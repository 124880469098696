import React from 'react';
import styled, { css } from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { Button, ButtonVariant } from '@naf/button';
import { NafThemeType, breakpoints, spacing, themeLight } from '@naf/theme';
import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { PortableText } from '@portabletext/react';
import { Link } from 'react-router-dom';
import {
  FeaturedBenefitBlockType,
  FeaturedBenefitStep,
  ColorVariant,
} from '../../../../../../types/featuredBenefitBlockType';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { useCloudinary } from '../../../../hooks/useCloudinary';
import { DynamicVideoPlayer } from '../../../video-player/DynamicVideoPlayer';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';

export const Wrapper = styled.div<{ $variant: ColorVariant; $breakEarly?: boolean }>`
  margin: 0 ${spacing.space40};
  display: flex;
  justify-content: center;
  padding: ${({ $variant }) =>
    $variant === 'outline' ? `0 ${spacing.space40}` : `${spacing.space80} ${spacing.space40} 0`};

  background-color: ${({ $variant, theme }) => {
    const activeTheme: NafThemeType = theme.background ? theme : themeLight;
    if ($variant === 'signature') {
      return activeTheme.background.important;
    }
    if ($variant === 'spruce') {
      return activeTheme.background.brandInverted;
    }
    if ($variant === 'outline') {
      return activeTheme.background.default;
    }
    return activeTheme.background.brand;
  }};

  color: ${({ $variant, theme }) => {
    const activeTheme: NafThemeType = theme.typography ? theme : themeLight;
    return $variant === 'spruce' ? activeTheme.typography.brandTextInverted : activeTheme.typography.defaultText;
  }};

  @media (max-width: ${({ $breakEarly }) => ($breakEarly ? breakpoints.l : breakpoints.m)}) {
    padding: ${spacing.space40} ${spacing.space24};
    margin: 0;
  }
`;

export const Content = styled.div`
  max-width: 1120px;
  width: 100%;
`;

export const Top = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${spacing.space64};

  @media (max-width: ${breakpoints.m}) {
    width: unset;
    margin-bottom: ${spacing.space40};
  }
`;

export const Tag = styled(Text)<{ $styleVariant: ColorVariant }>`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 14px;
  width: fit-content;
  padding: ${spacing.space4} ${spacing.space8};
  border-radius: ${spacing.space4};
  margin: 0 0 ${spacing.space8};

  background-color: ${({ $styleVariant, theme }) => {
    const activeTheme = theme.componentColors ? theme : themeLight;
    if ($styleVariant === ColorVariant.signature || $styleVariant === ColorVariant.outline) {
      return activeTheme.componentColors.cta.primary;
    }
    if ($styleVariant === ColorVariant.spruce) {
      return activeTheme.background.default;
    }
    return activeTheme.componentColors.cta.secondaryInverted;
  }};

  color: ${({ $styleVariant, theme }) => {
    const activeTheme: NafThemeType = theme?.typography ? theme : themeLight;
    if ($styleVariant === ColorVariant.signature) {
      return activeTheme.typography.brandText;
    }
    if ($styleVariant === ColorVariant.spruce) {
      return activeTheme.typography.brandText;
    }
    return activeTheme.typography.brandTextInverted;
  }};
`;

export const Heading = styled(Text)`
  margin-top: 0;
  margin-bottom: ${spacing.space8};
  * {
    margin: 0;
  }
  p {
    font-weight: 500;
  }
  p > strong {
    font-weight: 900;
  }
`;

export const Description = styled(Text)`
  margin: 0;
`;

export const getButtonStyle = (color: ColorVariant) => {
  const buttonStyle: { primary: ButtonVariant; secondary: ButtonVariant } = {
    primary: 'secondary',
    secondary: 'outline',
  };

  if (color === ColorVariant.signature || color === ColorVariant.outline) {
    buttonStyle.primary = 'signature';
  }

  if (color === ColorVariant.spruce) {
    buttonStyle.primary = 'secondary';
    buttonStyle.secondary = 'outline-inverted';
  }

  if (color === ColorVariant.secondary) {
    buttonStyle.primary = 'spruce';
  }

  return buttonStyle;
};

export const ButtonGroup: React.FC<
  Pick<FeaturedBenefitBlockType, 'colorVariant' | 'primaryCta' | 'secondaryCta'> & { children?: React.ReactNode }
> = ({ primaryCta, secondaryCta, colorVariant, children }) => {
  const primaryInternalItemUrl = useDocumentUrlWithNode(primaryCta?.internalLink);
  const secondaryInternalItemUrl = useDocumentUrlWithNode(secondaryCta?.internalLink);
  if (!primaryCta && !secondaryCta) return null;

  const { primary, secondary } = getButtonStyle(colorVariant);

  return (
    <StyledButtonGroup $breakEarly={!!children}>
      {children}
      {primaryCta && primaryCta.externalLink && primaryCta.externalLink.href && (
        <Button
          href={primaryCta.externalLink.href || ''}
          target={primaryCta.externalLink.blank ? '_blank' : '_self'}
          variant={primary}
        >
          {primaryCta.text}
        </Button>
      )}
      {primaryCta && primaryCta.internalLink && !primaryCta.externalLink?.href && (
        <Link to={primaryInternalItemUrl || ''}>
          <Button type="button" isNotClickable variant={primary}>
            {primaryCta.text}
          </Button>
        </Link>
      )}
      {secondaryCta && secondaryCta.externalLink && secondaryCta.externalLink.href && (
        <Button
          href={secondaryCta.externalLink.href || ''}
          target={secondaryCta.externalLink.blank ? '_blank' : '_self'}
          variant={secondary}
        >
          {secondaryCta.text}
        </Button>
      )}
      {secondaryCta && secondaryCta.internalLink && !secondaryCta.externalLink?.href && (
        <Link to={secondaryInternalItemUrl || ''}>
          <Button isNotClickable type="button" variant={secondary}>
            {secondaryCta.text}
          </Button>
        </Link>
      )}
    </StyledButtonGroup>
  );
};

const StyledButtonGroup = styled.div<{ $breakEarly?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: fit-content;

  button:not(:last-child),
  a:not(:last-child) {
    margin-bottom: ${spacing.space24};
  }

  @media (max-width: ${({ $breakEarly }) => ($breakEarly ? breakpoints.l : breakpoints.m)}) {
    width: 100%;
    button,
    a {
      width: 100%;
    }
  }
`;

export const Cover = styled.div<{ $breakEarly?: boolean }>`
  position: absolute;
  z-index: 2;
  height: 80px;
  width: 100%;
  background: ${({ theme }) => (theme?.background ? theme.background.default : themeLight.background.default)};
  bottom: 0;
  left: 0;

  @media (max-width: ${breakpoints.l}) {
    ${({ $breakEarly }) =>
      $breakEarly &&
      css`
        display: none;
      `};
    height: 24px;
  }

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${spacing.space64};

  div:not(:last-child) {
    margin-right: ${spacing.space40};
  }

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    margin-bottom: ${spacing.space40};
    div:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const StyledStep = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  width: 0;

  @media (max-width: ${breakpoints.m}) {
    flex: 1;
    width: 100%;
    margin-bottom: ${spacing.space24};
  }
`;

const StepCount = styled.div<{ $variant: ColorVariant }>`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  margin-bottom: ${spacing.space8};
  background-color: ${({ theme }) =>
    theme?.typography ? theme.typography.defaultText : themeLight.typography.defaultText};
  color: ${({ $variant, theme }) => {
    const activeTheme = theme?.background ? theme : themeLight;
    return $variant === 'signature' ? activeTheme.background.important : activeTheme.background.brand;
  }};

  * {
    margin: 0;
  }
`;

export const Step: React.FC<{ step: FeaturedBenefitStep; index: number; variant: ColorVariant }> = ({
  variant,
  step,
  index,
}) => (
  <StyledStep>
    <StepCount $variant={variant}>
      <Text variant={TextVariant.Header2}>{index}</Text>
    </StepCount>
    <Heading variant={TextVariant.Header2}>{step.title}</Heading>
    <Description variant={TextVariant.BodyText}>{step.description}</Description>
  </StyledStep>
);

export const MediaComponent: React.FC<Pick<FeaturedBenefitBlockType, 'image' | 'video' | 'illustration'>> = ({
  image,
  video,
  illustration,
}) => {
  const cld = useCloudinary();
  const publicId = image?.publicId || image?.public_id || undefined;
  const devicePixelRatio = useDevicePixelRatio();

  const cldImage = publicId
    ? cld
        .image(publicId)
        .delivery(dpr(devicePixelRatio))
        .quality('auto:good')
        .format(illustration ? 'svg' : 'auto')
    : undefined;

  if (video) {
    return (
      <DynamicVideoPlayer
        videoPlayerOptions={{ autoplay: true, autoplayMode: 'on-scroll', loop: true, muted: true }}
        video={video}
      />
    );
  }
  if (cldImage) {
    return (
      <AdvancedImage
        style={{ maxWidth: '100%' }}
        alt={image?.altText}
        cldImg={cldImage}
        plugins={[
          lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }),
          responsive({ steps: 200 }),
          placeholder({ mode: 'blur' }),
        ]}
      />
    );
  }
  return null;
};

export const HeadingBlock = styled(PortableText)``;
