import React from 'react';
import { TextVariant, Text } from '@naf/text';
import { breakpoints, spacing, themeLight } from '@naf/theme';
import styled from 'styled-components';
import { QnAItem } from '../QnaFeedBlock/QnaList';
import { ButtonGroup, Description, Heading, HeadingBlock, MediaComponent } from './StyledFeaturedBenefitBlock';
import { FeaturedBenefitBlockTypeProps } from './FeaturedBenefitBlock';

export const WithQnA: React.FC<FeaturedBenefitBlockTypeProps> = ({ block }) => {
  const { title, description, colorVariant, primaryCta, secondaryCta, questionsAndAnswersBlock, image } = block;
  const entries = questionsAndAnswersBlock?.qnaEntries?.filter((d) => d.publishedTime) || [];

  return (
    <Container>
      <Left>
        <InnerLeft>
          <ImageWrapper>
            <MediaComponent image={image} />
          </ImageWrapper>
          <StyledHeading variant={TextVariant.Header1}>
            <HeadingBlock value={title} />
          </StyledHeading>
          <Description>{description}</Description>
          <ButtonWrapper>
            <ButtonGroup primaryCta={primaryCta} secondaryCta={secondaryCta} colorVariant={colorVariant} />
          </ButtonWrapper>
        </InnerLeft>
      </Left>
      <Right>
        <InnerRight>
          <QnAItemWrapper>
            <QnATitle variant={TextVariant.DataLabel}>{questionsAndAnswersBlock?.title}</QnATitle>
            <ScrollContainer>
              {entries.map((entry, i) => (
                <ScrollItem isFirst={i === 0} isLast={i === entries.length - 1} key={entry._key}>
                  <QnAItem entry={entry} omitTimestamp />
                </ScrollItem>
              ))}
            </ScrollContainer>
          </QnAItemWrapper>
        </InnerRight>
      </Right>
    </Container>
  );
};

const ScrollContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
`;

const ScrollItem = styled.div<{ isFirst?: boolean; isLast?: boolean }>`
  scroll-snap-align: center;
  margin-left: ${({ isFirst }) => (isFirst ? spacing.space80 : spacing.space32)};
  margin-right: ${({ isLast }) => (isLast ? spacing.space80 : '0px')};
  min-width: calc(100% - 160px);

  @media (max-width: ${breakpoints.l}) {
    margin-left: ${({ isFirst }) => (isFirst ? spacing.space40 : spacing.space32)};
    margin-right: ${({ isLast }) => (isLast ? spacing.space40 : '0px')};
    min-width: calc(100% - 120px);
  }

  @media (max-width: ${breakpoints.s}) {
    margin-left: ${({ isFirst }) => (isFirst ? spacing.space24 : spacing.space16)};
    margin-right: ${({ isLast }) => (isLast ? spacing.space24 : '0px')};
    min-width: calc(100% - 56px);
  }
`;

const QnATitle = styled(Text)`
  padding-left: ${spacing.space80};
  margin: 0;
  @media (max-width: ${breakpoints.l}) {
    padding-left: ${spacing.space40};
  }

  @media (max-width: ${breakpoints.s}) {
    padding-left: ${spacing.space24};
  }
`;

const ButtonWrapper = styled.div`
  margin-top: ${spacing.space32};
`;

const Container = styled.div`
  border: 2px solid ${({ theme }) => (theme?.background ? theme.background.brand : themeLight.background.brand)};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpoints.l}) {
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.s}) {
    margin: 0;
    margin-left: -24px;
    width: calc(100vw - 4px);
  }
`;

const InnerLeft = styled.div`
  padding: ${spacing.space24} ${spacing.space80} ${spacing.space40} ${spacing.space40};

  @media (max-width: ${breakpoints.l}) {
    max-width: 100%;
    padding: ${spacing.space24} ${spacing.space40};
  }

  @media (max-width: ${breakpoints.s}) {
    padding: ${spacing.space16} ${spacing.space24};
  }
`;

const InnerRight = styled.div`
  padding: ${spacing.space40} 0;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.l}) {
    max-width: 100%;
    padding: ${spacing.space24} 0;
  }

  @media (max-width: ${breakpoints.s}) {
    padding: ${spacing.space16} 0;
  }
`;

const QnAItemWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Left = styled.div`
  background: ${({ theme }) => (themeLight?.background ? theme.background.brand : themeLight.background.brand)};
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: ${breakpoints.l}) {
    width: 100%;
  }
  z-index: 4;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${spacing.space24};
`;

const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.l}) {
    width: 100%;
    height: 100%;
    min-height: 600px;
  }
`;

const ImageWrapper = styled.div`
  width: ${spacing.space120};
  height: ${spacing.space120};
  margin-bottom: ${spacing.space32};
  img {
    height: 100%;
    width: 100%;
  }
`;
